import * as React from "react"
import styled from 'styled-components'
import { graphql, Link } from "gatsby"
import { TransitionMask } from '@components'
import { H2 } from '@components/BlockRenderer'
import { Grid, GridItemWrapper, GridItem, } from '@components/grid'
import { PageProps } from '@types'

const NotFoundPage = ({data: { 
  sanityPage: {
    gridItems,
  }
}}: PageProps) => {
  return (
    <>
      <StyledGrid>
        <GridItemWrapper >
          <StyledH2><StyledLink to="/" >404 Error</StyledLink></StyledH2>
        </GridItemWrapper>
        {gridItems?.filter(p => !!p).map(item => (
          <StyledGridItem key={item._key} {...item} />
        ))}
      </StyledGrid>
      
      <TransitionMask />
    </>
  )
}

const StyledGrid = styled(props => <Grid {...props} />)`
  margin: 0 auto;
  min-height: 100vh;
  grid-row-gap: 0;
  grid-template-rows: 200px auto;
`

const StyledGridItem = styled(props => <GridItem {...props} />)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 200px;
`

const StyledH2 = styled(props => <H2 {...props} />)`
  text-align: center;
  transform: rotateZ(180deg);
  transform-origin: center center;
  margin: 0;
  padding: 0 0 1em 0;
`

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
`

export const query = graphql`
  query FourOhFourPageQuery {
    sanityPage(slug: {current: {eq: "404"}}) {
      ...pageFields
    }
  }
`

export default NotFoundPage
